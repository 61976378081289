import {createRouter, createWebHistory, RouteRecordName, RouteRecordRaw} from 'vue-router'
import store from "@/store";

const Principal = () => import('../templates/TemplatePrincipal.vue')

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'TelaLogin',
        component: () => import('@/views/TelaLogin.vue'),
    },
    {
        path: '/',
        name: 'TemplatePrincipal',
        component: Principal,
        children: [

            {
                path: '/',
                name: 'BuscarFornecedor',
                component: () => import('@/views/BuscarFornecedor.vue'),
            },
            {
                path: '/cadastrar-fornecedor',
                name: 'CadastrarFornecedor',
                component: () => import('@/views/CadastrarFornecedor.vue'),
            },
            {
                path: '/cadastrar-peca',
                name: 'CadastrarPeca',
                component: () => import('@/views/CadastrarPeca.vue'),
            },
            {
                path: '/detalhes-produto',
                name: 'DetalhesProduto',
                component: () => import('@/views/DetalhesProduto.vue'),
            },
            {
                path: '/cesta-pecas',
                name: 'CestaPecas',
                component: () => import('@/views/CestaPecas.vue'),
            },
            {
                path: 'avaliacao-fornecedor',
                name: 'AvaliacaoFornecedor',
                component: () => import('@/views/AvaliacaoFornecedor.vue'),
            },
            {
                path: 'conclusao-compra',
                name: 'ConclusaoCompra',
                component: () => import('@/views/ConclusaoCompra.vue'),
            }
        ]
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const authenticatedRoutes: Array<RouteRecordName | null | undefined> = [
    'BuscarFornecedor',
    'CadastrarFornecedor',
    'CadastrarPeca',
    'DetalhesProduto',
]

const buyRoutes: Array<RouteRecordName | null | undefined> = [
    'CestaPecas',
    'AvaliacaoFornecedor',
    'ConclusaoCompra',
    'CadastrarPeca',
    'DetalhesProduto',
    'CestaPecas',
];

router.beforeEach((to, from, next) => {
    if (to.name === 'TelaLogin' && localStorage.getItem('token')) {
        next({ name: 'BuscarFornecedor' })
    } else if (buyRoutes.includes(to.name) && store.state.compra.fornecedor.nome === '') {
        next({ name: 'BuscarFornecedor' })
    } else {
        if (authenticatedRoutes.includes(to.name)) {
            if (!localStorage.getItem('token')) {
                next({ name: 'TelaLogin' })
            } else {
                next()
            }
        } else {
            next()
        }
    }
});

export default router
