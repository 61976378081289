import { createStore } from 'vuex';
import { Produto, State, Usuario, Categoria } from "@/settings/interfaces";
import VuexPersistence from 'vuex-persist'


const vuexLocal = new VuexPersistence<State>({
    key: 'compra',
    reducer: (state) => ({ compra: state.compra }),
    saveState: (key, state) => {
        sessionStorage.setItem(key, JSON.stringify(state));
    },
    restoreState: (key) => {
        const encoded = sessionStorage.getItem(key);
        if (encoded) {
            return JSON.parse(encoded);
        }
        return {
            fornecedor: {
                id: 0,
                nome: '',
                celular: '',
                nota: 0
            },
            produtos: new Array<Produto>(),
            atual: 0,
            valor: 0,
            porcentagem: 0,
        };
    }
})

const usuario = localStorage.getItem('usuario')
const usuarioJson: Usuario = usuario ? JSON.parse(usuario) : { id: 0 }

export default createStore({
    state: {
        usuario: usuarioJson,
        compra: {
            fornecedor: {
                id: 0,
                nome: '',
                celular: '',
                nota: 0,
                cpf: '',
            },
            produtos: new Array<Produto>(),
            atual: 0,
            valor: 0,
        },
        carregando: false,
    },
    getters: {
        nomeFornecedor: state => state.compra.fornecedor.nome,
        fornecedor: state => state.compra.fornecedor,
        quantidadeCategorias: (state): number => {
            if (state.compra.produtos[state.compra.atual]) {
                return state.compra.produtos[state.compra.atual].categorias.length
            } else {
                return 0
            }
        },
        quantidadeProdutos: (state): number => {
            return state.compra.produtos.length
        },
        ultimaCategoria: state => {
            if (state.compra.produtos[state.compra.atual]) {
                return state.compra.produtos[state.compra.atual].categorias[state.compra.produtos[state.compra.atual].categorias.length - 1]
            } else {
                return null
            }
        },
        categoriasAtual: state => {
            if (state.compra.produtos[state.compra.atual]) {
                return state.compra.produtos[state.compra.atual].categorias
            } else {
                return []
            }
        },
        produtos: state => state.compra.produtos,
        produtoAtual: (state): Produto => {
            return state.compra.produtos[state.compra.atual]
        },
        carregando: state => state.carregando,
    },
    mutations: {
        removeUsuario(state) {
            state.usuario = {
                id: 0,
                nome: '',
                email: '',
                tipo: '',
                ativo: false,
            }
            localStorage.removeItem('usuario')
        },
        setUsuario(state, usuario) {
            state.usuario = usuario
            localStorage.setItem('usuario', JSON.stringify(usuario))
        },
        setFornecedor(state, fornecedor) {
            state.compra.fornecedor = fornecedor
        },
        clearCompra(state) {
            state.compra = {
                fornecedor: {
                    id: 0,
                    nome: '',
                    celular: '',
                    nota: 0,
                    cpf: '',
                },
                produtos: Array<Produto>(),
                atual: 0,
                valor: 0,
            }
        },
        addCategoria(state, categoria: Categoria) {
            const index = state.compra.atual;
            state.compra.produtos[index].categorias.push(categoria);
        },
        removerUltimaCategoria(state) {
            const index = state.compra.atual;
            state.compra.produtos[index].categorias.pop();
        },
        addProduto(state) {
            state.compra.produtos.push({
                categorias: Array<Categoria>(),
                detalhes: {
                    estrelas: 0,
                    nome: '',
                    id_marca: 0,
                    observacao: '',
                    marca: '',
                    preco_custo: 0,
                    preco_venda: 0,
                }
            });
        },
        changeAtual(state, atual: number) {
            state.compra.atual = atual;
        },
        removeProduto(state, index: number) {
            state.compra.produtos.splice(index, 1);
        },
        removerAtual(state) {
            state.compra.produtos.splice(state.compra.atual, 1);
        },
        setValor(state, valor: number) {
            state.compra.valor = valor;
        },
        setCarregando(state, carregando: boolean) {
            state.carregando = carregando;
        }
    },
    actions: {
        novoProduto({ commit }) {
            this.state.compra.atual = this.state.compra.produtos.length;
            commit('addProduto');
        },
        logout({ commit }) {
            commit('removeUsuario');
            commit('clearCompra');
        }
    },
    modules: {
    },
    plugins: [vuexLocal.plugin],
})
