import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap";

const options: PluginOptions = {
    transition: "Vue-Toastification__bounce",
    maxToasts: 3,
    newestOnTop: true,
    hideProgressBar: true,
    timeout: 1500,
    pauseOnHover: false,
};

createApp(App)
    .use(store)
    .use(router)
    .use(VueSweetalert2)
    .use(Toast, options)
    .mount('#app')
